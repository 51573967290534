



































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { ttt } from '@/nutils';

import {
  readUserProfile,
  readHasAdminAccess,
  readUserBrand,
  readUserKPIBrand,
  readNewest3,
} from '@/store/main/getters';
import {
  readAffiBrands,
  readKPIBrands,
  readImpersonated,
} from '@/store/affi/getters';
import {
  dispatchGetUserKPIBrand,
  dispatchGetNewest3,
} from '@/store/main/actions';
import {
  dispatchGetBrands,
  dispatchGetKPIBrands,
  dispatchAskPayment,
} from '@/store/affi/actions';
import { IBrand } from '@/interfaces';

@Component
export default class Dashboard extends Vue {

  public ttt = ttt;

  public headers = [
    { text: ttt('Name'), sortable: false, value: 'shop_name', align: 'left', },
    { text: ttt('Description'), sortable: false, value: 'shop_description', align: 'left', },
  ];

  get greetedUser() {
    if (readHasAdminAccess(this.$store)) {
        return '';
    } else {
        const userProfile = readUserProfile(this.$store);
        if (userProfile) {
          if (userProfile.first_name && userProfile.last_name) {
            return `${userProfile.first_name} ${userProfile.last_name}`;
          } else {
            return userProfile.email;
          }
        }
    }
  }

  get myBrand() {
    const imp = readImpersonated(this.$store);
    const bs = readAffiBrands(this.$store)
        .filter( (b) => ( imp === -1 || b && b.owner_id === imp ) );
    return bs[0];
  }

  get newestBrands() {
    const n3 = readNewest3(this.$store);
    // console.log(n3);
    return n3;
  }

  get myKPIs() {
    const imp = readImpersonated(this.$store);
    const bs = readKPIBrands(this.$store)
        .filter( (b) => ( imp === -1 || b && b.owner_id === imp ) );
    return bs[0];
  }

  public async mounted() {
    await dispatchGetBrands(this.$store);
    await dispatchGetKPIBrands(this.$store);
    await dispatchGetNewest3(this.$store);
  }

  public async askPayment() {
    const data = {
      callback: ( resp: any ) => {
        // tslint:disable-next-line:no-console
        console.log(`going to ${resp}`);
        window.location.href = resp.msg;
      }
    };
    await dispatchAskPayment(this.$store, data);
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public get impersonated() {
    return readImpersonated(this.$store);
  }
}
